import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { customersSlice } from "../app/modules/ECommerce/_redux/customers/customersSlice";
// import { productsSlice } from "../app/modules/ECommerce/_redux/products/productsSlice";
import { remarksSlice } from "../app/modules/ECommerce/_redux/remarks/remarksSlice";
import { specificationsSlice } from "../app/modules/ECommerce/_redux/specifications/specificationsSlice";
import { branchSlice, saga as branchSaga } from "../app/modules/Vendors/_redux/branchRedux";
import { marketSlice } from "../app/modules/Vendors/_redux/marketRedux";
import { voucherSlice } from "../app/modules/Loyalty/_redux/voucherRedux";
import { orderSlice, saga as orderSaga } from "../app/modules/Order/_redux/orderRedux";
import {
  productSlice,
  saga as productSaga,
} from "../app/modules/Product_Categories/_redux/productRedux";
import { categorySlice } from "../app/modules/Product_Categories/_redux/categoryRedux";
import { staffSlice } from "../app/modules/Vendors/_redux/staffRedux";
import { rewardSlice } from "../app/modules/Loyalty/_redux/rewardRedux";
import { dashboardSlice } from "../app/modules/Dashboard/_redux/dashboardRedux";
import { faqSlice } from "../app/modules/App/_redux/FaqRedux";
import { termsOfUseState } from "../app/modules/App/_redux/termsOfUseRedux";
import { recipeState } from "../app/modules/Recipe/_redux/recipeRedux";
import { recipeCategorySlice } from "../app/modules/Recipe/_redux/categoryRedux";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  customers: customersSlice.reducer,
  // products: productsSlice.reducer,
  remarks: remarksSlice.reducer,
  specifications: specificationsSlice.reducer,
  branch: branchSlice.reducer,
  market: marketSlice.reducer,
  voucher: voucherSlice.reducer,
  order: orderSlice.reducer,
  product: productSlice.reducer,
  category: categorySlice.reducer,
  staff: staffSlice.reducer,
  reward: rewardSlice.reducer,
  dashboard: dashboardSlice.reducer,
  faq: faqSlice.reducer,
  termsofuse: termsOfUseState.reducer,
  recipe: recipeState.reducer,
  recipe_category: recipeCategorySlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga(), branchSaga(), orderSaga(), productSaga()]);
}
