import { createSlice } from "@reduxjs/toolkit";

const initialDashboardState = {
  filter: {
    start_date: "",
    end_date: "",
    duration: "month",
  },
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialDashboardState,
  reducers: {
    // filter dashboard
    setDashboardFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
});

const { actions } = dashboardSlice;

export const setDashboardFilter = (filterData) => (dispatch) => {
  return dispatch(actions.setDashboardFilter(filterData));
};

export function* saga() {}
