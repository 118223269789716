import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import SVG from "react-inlinesvg";
import * as authRedux from "../_redux/authRedux";
import { provider, auth } from "../../../../utilities/firebase";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithRedirect,
  signOut,
} from "firebase/auth";
import { LayoutSplashScreen } from "../../../../_metronic/layout";
import { getUserRole } from "../../../../services/auth";
import {
  SHOPPER,
  SUPERADMIN,
  SUPERVISOR,
  SUPERVISOR_MERCHANT,
} from "../../../../variables/global.js";
/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

function Login(props) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [formData, setFormData] = useState({ email: "", password: "" });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const onGoogleLogin = async () => {
    await signInWithRedirect(auth, provider);
  };

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const submitLoginEmail = () => {
    enableLoading();
    const { email, password } = formData;
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        const { email } = user;
        getUserRole(email)
          .then((res) => {
            // check if user have admin role
            const roles = res.roles;
            if (
              roles?.includes(SUPERADMIN) ||
              roles?.includes(SUPERVISOR) ||
              roles?.includes(SUPERVISOR_MERCHANT) ||
              roles?.includes(SHOPPER)
            ) {
              props.fulfillUser({ ...res, user });
              disableLoading();
            } else {
              signOut(auth).then(() => {
                setErrorMessage("maaf anda bukan admin");
              });
            }
          })
          .catch((err) => {
            signOut(auth).then(() => {
              setErrorMessage("maaf email tidak terdaftar");
              disableLoading();
            });
            console.log(err);
          });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
      });
  };

  useEffect(() => {
    enableLoading();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const { email } = user;
        getUserRole(email)
          .then((res) => {
            // check if user have admin role
            const roles = res.roles;
            if (
              roles?.includes(SUPERADMIN) ||
              roles?.includes(SUPERVISOR) ||
              roles?.includes(SUPERVISOR_MERCHANT) ||
              roles?.includes(SHOPPER)
            ) {
              props.fulfillUser({ ...res, user });
              props.setUserRoles(roles);
            } else {
              signOut(auth).then(() => {
                setErrorMessage("maaf anda bukan admin");
              });
            }
          })
          .catch((err) => {
            signOut(auth).then(() => {
              setErrorMessage("maaf email tidak terdaftar");
              disableLoading();
            });
            console.log(err);
          });
      } else {
        disableLoading();
        // setErrorMessage("maaf anda belum login");
      }
    });
  }, []);

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">Use your admin role gmail account</p>
      </div>
      {/* end::Head */}

      <div className="d-flex align-items-center justify-content-center flex-column">
        {errorMessage && (
          <div className=" my-2 py-1 px-2 bg-white text-danger">
            <p>{errorMessage}</p>
          </div>
        )}
        {loading ? (
          <LayoutSplashScreen />
        ) : (
          <div>
            <div className="form-group fv-plugins-icon-container">
              <input
                onChange={onChangeHandler}
                placeholder="Email"
                type="email"
                className="form-control form-control-solid h-auto py-5 px-6"
                name="email"
              />
            </div>
            <div className="form-group fv-plugins-icon-container">
              <input
                onChange={onChangeHandler}
                placeholder="Password"
                type="password"
                className="form-control form-control-solid h-auto py-5 px-6"
                name="password"
              />
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <button
                onClick={submitLoginEmail}
                className={`btn btn-primary font-weight-bold px-6 py-2 my-2`}
              >
                <span>Sign In</span>
              </button>
            </div>
            <div className="d-flex align-items-center justify-content-center mt-2">
              <button className="btn btn-outline-primary" onClick={onGoogleLogin}>
                <SVG className="mr-2" src={toAbsoluteUrl("/media/svg/social-icons/google.svg")} />
                Sign in with Google{" "}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default injectIntl(connect(null, authRedux.actions)(Login));
