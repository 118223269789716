import moment from "moment-timezone";
import "moment/locale/id";
moment.locale("id");

export const mapSnapshootToDocs = (snapshoot) => {
  if (!snapshoot.docs.length) return [];
  return snapshoot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};

export const CurrencyFormatter = new Intl.NumberFormat("id-ID", {
  style: "currency",
  currency: "IDR",
  minimumFractionDigits: 0,
});

export const UtcToLocal = (dateUtc) => {
  const stillUtc = moment.utc(dateUtc).toDate();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return moment(stillUtc)
    .local("id")
    .tz(timezone)
    .format("LL HH:mm z");
};

export const generateKeywordFull = (keys) => {
  const arrayData = [];

  keys.split(" ").forEach((data) => {
    arrayData.push(data);
  });

  keys.split(" (").forEach((data) => {
    arrayData.push(data);
  });
  arrayData.push(keys);

  return arrayData;
};

export const generateKeyword = (keyword) => {
  let keywordArr = keyword.toLowerCase().split(" ");
  let temp = "";

  keywordArr.forEach((word, index) => {
    temp += ` ${word}`;
    if (index === 0) temp = `${word}`;
    if (!keywordArr.includes(temp)) {
      keywordArr.push(temp);
    }
  });

  return keywordArr;
};

export const createLocalGMTTimestamp = (format_date = new Date()) => {
  const dateGMT =
    format_date.getUTCFullYear() +
    "-" +
    ("0" + (format_date.getUTCMonth() + 1)).slice(-2) +
    "-" +
    ("0" + format_date.getUTCDate()).slice(-2) +
    "T" +
    ("0" + format_date.getUTCHours()).slice(-2) +
    ":" +
    ("0" + format_date.getUTCMinutes()).slice(-2) +
    ":" +
    ("0" + format_date.getUTCSeconds()).slice(-2);

  const dateLocal = moment(format_date)
    .tz("Asia/Jakarta")
    .format("YYYY-MM-DDTHH:mm:ss");

  return [dateLocal, dateGMT];
};
