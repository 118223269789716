import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../../../../utilities/firebase";
import { LayoutSplashScreen } from "../../../../_metronic/layout";
import * as authRedux from "../_redux/authRedux";

class Logout extends Component {
  componentDidMount() {
    signOut(auth)
      .then(() => {
        console.log("logout succes");
        this.props.logout();
      })
      .catch((err) => console.log(err));
  }

  render() {
    const { hasAuthToken } = this.props;
    return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth/login" />;
  }
}

export default connect(({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }), authRedux.actions)(Logout);
