import { createSlice } from "@reduxjs/toolkit";
import { takeEvery, select, put } from "redux-saga/effects";
import { postNewBranch } from "../../../../services/branch";

const initialMarketState = {
  pic: { fname: "", lname: "", email: "", callname: "", password: "", username: "" },
  detail: {
    city: "",
    name: "",
    postcode: "",
    city_registered: "",
    phone: "",
    description: "",
  },

  newMarket: {},
  editMarket: {},
};

export const marketSlice = createSlice({
  name: "market",
  initialState: initialMarketState,
  reducers: {
    setPicMarket: (state, action) => {
      if (action.payload) state.pic = action.payload;
    },
    setMarketDetail: (state, action) => {
      if (action.payload) state.detail = action.payload;
    },
    setCreatedMarket: (state, action) => {
      if (action.payload) state.newMarket = action.payload;
    },
    setEditMarket: (state, action) => {
      if (action.payload) state.editMarket = action.payload;
    },
    clearMarketState: (state, action) => {
      state.pic = initialMarketState.pic;
      state.detail = initialMarketState.detail;
    },
  },
});

const { actions } = marketSlice;

export const setPicMarket = (picData) => (dispatch) => {
  return dispatch(actions.setPicMarket(picData));
};

export const setMarketDetail = (marketDetailData) => (dispatch) => {
  return dispatch(actions.setMarketDetail(marketDetailData));
};

export const setCreatedMarket = (newMarketData) => (dispatch) => {
  return dispatch(actions.setCreatedMarket(newMarketData));
};

export const setEditMarket = (market) => (dispatch) => {
  return dispatch(actions.setEditMarket(market));
};
export const clearMarketState = () => (dispatch) => {
  return dispatch(actions.clearMarketState());
};

export function* saga() {
  yield takeEvery(actions.postBranch, function* postNewBranchSaga() {
    const { branch } = yield select();
    const newBranch = yield postNewBranch(branch);
    yield put(actions.setCreatedBranch(newBranch));
  });

  yield takeEvery(actions.postUpdateBranch, function* postUpdateBranchSaga() {
    // const { branch } = yield select();
    // const newBranch = yield postNewBranch(branch);
    // yield put(actions.setCreatedBranch(newBranch));
  });
}
