import { createSlice } from "@reduxjs/toolkit";

const initialCategoryState = {
  edit_category: {
    initial_category: {},
    category_name: "",
    category_desc: "",
    category_thumbnail: {},
  },
};

export const recipeCategorySlice = createSlice({
  name: "recipe_category",
  initialState: initialCategoryState,
  reducers: {
    setNewRecipeCategory: (state, action) => {
      state.new_category = { ...state.new_category, ...action.payload };
    },
    setInitialRecipeCategory: (state, action) => {
      state.edit_category.initial_category = action.payload;
    },
    setEditRecipeCategory: (state, action) => {
      state.edit_category = { ...state.edit_category, ...action.payload };
    },
  },
});

const { actions } = recipeCategorySlice;

export const setNewRecipeCategory = (categoryData) => (dispatch) => {
  return dispatch(actions.setNewRecipeCategory(categoryData));
};
export const setInitialRecipeCategory = (initialCategoryData) => (dispatch) => {
  return dispatch(actions.setInitialRecipeCategory(initialCategoryData));
};
export const setEditRecipeCategory = (categoryData) => (dispatch) => {
  return dispatch(actions.setEditRecipeCategory(categoryData));
};
