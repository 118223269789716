import { createSlice } from "@reduxjs/toolkit";

const initialRewardState = {
  product: {},
  voucher: {},
  initialData: {},
};

export const rewardSlice = createSlice({
  name: "reward",
  initialState: initialRewardState,
  reducers: {
    setInitialData: (state, action) => {
      state.initialData = action.payload;
    },
    setRewardProduct: (state, action) => {
      state.product = action.payload;
    },
    setRewardVoucher: (state, action) => {
      state.voucher = action.payload;
    },
    resetRewardForm: (state, action) => {
      state.product = initialRewardState.product;
      state.voucher = initialRewardState.voucher;
    },
  },
});

const { actions } = rewardSlice;

export const setInitialData = (data) => (dispatch) => {
  return dispatch(actions.setInitialData(data));
};

export const setRewardProduct = (voucherData) => (dispatch) => {
  return dispatch(actions.setRewardProduct(voucherData));
};

export const setRewardVoucher = (voucherDetail) => (dispatch) => {
  return dispatch(actions.setRewardVoucher(voucherDetail));
};

export const resetRewardForm = () => (dispatch) => {
  return dispatch(actions.resetRewardForm());
};
