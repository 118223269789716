import { createSlice } from "@reduxjs/toolkit";

const initialTermsOfUseState = {
  title: "",
  description: "",
  list_content: [{ content: "", sequence: null, title: "" }],
};

export const termsOfUseState = createSlice({
  name: "termsofuse",
  initialState: initialTermsOfUseState,
  reducers: {
    setSelectedContent: (state, action) => (state = { ...action.payload }),
  },
});

const { actions } = termsOfUseState;

export const setSelectedContent = (data) => (dispatch) => {
  return dispatch(actions.setSelectedContent(data));
};
