import { createSlice } from "@reduxjs/toolkit";

const initialRecipeState = {
  initialRecipeData: {},
  cooking_step: [],
  ingredients: [],
};

export const recipeState = createSlice({
  name: "recipe",
  initialState: initialRecipeState,
  reducers: {
    setEditRecipe: (state, action) => {
      state.initialRecipeData = { ...action.payload };
    },
    setCookingStep: (state, action) => {
      state.cooking_step = action.payload;
    },
    setIngredients: (state, action) => {
      state.ingredients = action.payload;
    },
    clearRecipeForm: (state, action) => {
      state = initialRecipeState;
    },
  },
});

const { actions } = recipeState;

export const setEditRecipe = (data) => (dispatch) => {
  return dispatch(actions.setEditRecipe(data));
};

export const setCookingStep = (data) => (dispatch) => {
  return dispatch(actions.setCookingStep(data));
};

export const setIngredients = (data) => (dispatch) => {
  return dispatch(actions.setIngredients(data));
};

export const clearRecipeForm = () => (dispatch) => {
  return dispatch(actions.clearRecipeForm());
};
