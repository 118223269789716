import { createSlice } from "@reduxjs/toolkit";

const initialVoucherState = {
  voucher: {
    voucher_name: "",
    voucher_creator: "",
    branch__linked: "",
    start_date: "",
    end_date: "",
    voucher_code: "",
  },
  voucher_detail: {
    banner: "",
    discount_percentage: "",
    limit_order_customer: "",
    maximum_discount: "",
    minimum_spend: "",
    terms_conditions: "",
    bank: "",
    customer_login: true,
    voucher_type: "discount",
    status: "active",
    is_hidden: false,
  },
};

export const voucherSlice = createSlice({
  name: "voucher",
  initialState: initialVoucherState,
  reducers: {
    setVoucher: (state, action) => {
      state.voucher = action.payload;
    },
    setVoucherDetail: (state, action) => {
      state.voucher_detail = action.payload;
    },
    resetVoucherForm: (state, action) => {
      state.voucher = initialVoucherState.voucher;
      state.voucher_detail = initialVoucherState.voucher_detail;
    },
  },
});

const { actions } = voucherSlice;

export const setVoucher = (voucherData) => (dispatch) => {
  return dispatch(actions.setVoucher(voucherData));
};

export const setVoucherDetail = (voucherDetail) => (dispatch) => {
  return dispatch(actions.setVoucherDetail(voucherDetail));
};

export const resetVoucherForm = () => (dispatch) => {
  return dispatch(actions.resetVoucherForm());
};
