import axios from "axios";
import { db } from "../utilities/firebase";
import {
  collection,
  getDocs,
  doc,
  setDoc,
  query,
  orderBy,
  limit,
  startAfter,
  startAt,
  where,
  deleteDoc,
  addDoc,
  Timestamp,
} from "firebase/firestore";
import { MAX_ITEM_PERPAGE } from "../variables/global";
import { deleteFile } from "./files";
import { mapSnapshootToDocs } from "../utilities/formatter";

const cloudUrl = process.env.REACT_APP_CLOUD_FUNCTION;

export const getVoucherList = async ({ status, PAGE }) => {
  try {
    const voucherRef = collection(db, "vouchers");
    let START_FROM = (PAGE - 1) * MAX_ITEM_PERPAGE;
    // Query the first page of docs
    if (PAGE === 1) START_FROM = 1;

    const queryArr = [];
    status !== "all" && queryArr.push(where("status", "==", status));
    queryArr.push(orderBy("created_at", "desc"));

    let first = query(voucherRef, ...queryArr, limit(START_FROM));

    const firstSnapshot = await getDocs(first);
    let lastVisible = null;
    if (firstSnapshot.docs.length) {
      lastVisible = firstSnapshot.docs[firstSnapshot.docs.length - 1];
    }

    // Construct a new query starting at this document,
    let next = query(
      voucherRef,
      ...queryArr,
      PAGE === 1 ? startAt(lastVisible) : startAfter(lastVisible),
      limit(MAX_ITEM_PERPAGE)
    );

    const nextSnapshoot = await getDocs(next);
    const voucherList = mapSnapshootToDocs(nextSnapshoot);

    const formattedVoucher = voucherList.map((v) => {
      return {
        ...v,
        city: v.city === undefined ? [""] : typeof v.city === "string" ? [v.city] : v.city,
        created_at: v.created_at ? v.created_at.toDate() : null,
        updated_at: v.updated_at ? v.updated_at.toDate() : null,
      };
    });
    return formattedVoucher || [];
  } catch (err) {
    console.log(err);
  }
};

export const createNewVoucher = async (voucherData) => {
  let keyword = [];
  keyword.push(voucherData.voucher_name.toLowerCase());
  voucherData.city.forEach((c) => {
    keyword.push(c === "ALL" ? "semua cabang" : c.toLowerCase());
  });
  const voucherRef = collection(db, "vouchers");
  const data = {
    ...voucherData,
    search: [...keyword],
    start_date: voucherData.start_date,
    end_date: voucherData.end_date,
    created_at: new Timestamp(Math.floor(new Date().getTime() / 1000), 0),
  };
  return await addDoc(voucherRef, data);
};

export const getLinkedBranch = async () => {
  try {
    const cityRef = collection(db, "branches");
    const q = query(cityRef);
    const citySnapshot = await getDocs(q);
    return mapSnapshootToDocs(citySnapshot);
  } catch (err) {
    console.log(err);
  }
};

export const deleteVoucher = async (voucher) => {
  try {
    const voucherId = voucher.id;
    const fileRef = voucher.filePath;
    if (fileRef) await deleteFile(fileRef);
    return await deleteDoc(doc(db, "vouchers", voucherId.toString()));
  } catch (err) {
    console.log(err);
  }
};

export const setInactiveVoucher = async (voucher) => {
  try {
    const voucherId = voucher.id;
    const voucherRef = doc(db, "vouchers", voucherId.toString());
    return await setDoc(voucherRef, { status: "inactive" }, { merge: true });
  } catch (err) {
    console.log(err);
  }
};

export const setActiveVoucher = async (voucher) => {
  try {
    const voucherId = voucher.id;
    const voucherRef = doc(db, "vouchers", voucherId.toString());
    return await setDoc(voucherRef, { status: "active" }, { merge: true });
  } catch (err) {
    console.log(err);
  }
};

export const editVoucher = async (voucherData) => {
  let keyword = [];
  keyword.push(voucherData.voucher_name.toLowerCase());
  voucherData.city.forEach((c) => {
    if (c === "ALL") {
      keyword.push("semua");
      keyword.push("cabang");
    } else {
      keyword.push(c.toLowerCase());
    }
  });

  const { id: voucherId } = voucherData;
  const timestamp = new Timestamp(Math.floor(new Date().getTime() / 1000), 0);
  const voucherRef = doc(db, "vouchers", voucherId);
  return await setDoc(
    voucherRef,
    {
      ...voucherData,
      updated_at: timestamp,
      city: voucherData.city,
      search: [...keyword],
    },
    { merge: true }
  );
};

export const getVoucherDetail = async (voucherCode, email) => {
  const voucherDetailUrl = `${cloudUrl}/orders/voucher_detail/${voucherCode}/email/${email}`;
  return axios.get(voucherDetailUrl);
};

export const voucherValidation = (data) => {
  const voucherValidationUrl = `${cloudUrl}/orders/voucher_validation`;
  return axios.post(voucherValidationUrl, data);
};

export const voucherValidationAdmin = (data) => {
  const voucherValidationUrl = `${cloudUrl}/orders/voucher_validation_admin`;
  return axios.post(voucherValidationUrl, data);
};

export const voucherCancelAdmin = (data) => {
  const voucherCancelUrl = `${cloudUrl}/orders/voucher_cancel_admin`;
  return axios.post(voucherCancelUrl, data);
};

export const getVoucherByKeyword = async (keyword) => {
  const voucherRef = collection(db, "vouchers");
  let q = query(
    voucherRef,
    where("search", "array-contains", keyword.trim()),
    orderBy("voucher_name")
  );
  try {
    const snapshot = await getDocs(q);
    const voucherList = mapSnapshootToDocs(snapshot);

    const formattedVoucher = voucherList.map((v) => {
      return {
        ...v,
        city: v.city === undefined ? [""] : typeof v.city === "string" ? [v.city] : v.city,
        created_at: v.created_at ? v.created_at.toDate() : null,
        updated_at: v.updated_at ? v.updated_at.toDate() : null,
      };
    });
    return Promise.resolve(formattedVoucher);
  } catch (err) {
    return Promise.reject(err);
  }
};
