import { SHOPPER, SUPERADMIN, SUPERVISOR, SUPERVISOR_MERCHANT } from "../variables/global";

const publicRoute = ["/auth/login"];
const supervisorRoute = [
  //dashboard
  "/dashboard",
  // vendor
  "/vendor/market-list",
  "/vendor/create-market",
  "/vendor/edit-market",
  "/vendor/created-market",
  // staff
  "/vendor/staff",
  "/vendor/create-courier",
  "/vendor/create-shopper",
  "/vendor/edit-courier",
  "/vendor/edit-shopper",
  // order
  "/order/order-list",
  "/order/create-order",
  "/order/edit-order",
  "/order/edit-customer-address",
  // export order
  "/order/export-order",
  // product
  "/e-commerce/products",
  "/e-commerce/create-product",
  "/e-commerce/edit-product",
  // export product
  "/e-commerce/export-product",
  // redeem reward
  "/loyalty/redeem-reward",
  // customer list
  "/loyalty/customer-list",
  // management discount
  "/loyalty/discount-management",
  //withdrawal
  "/withdrawal/withdrawal-list",
  // order shopper
  "/shopper/order-list",
  // product order
  "/product-order-management/product-list",
  "/product-order-management/shopper-attendance",
  "/shopper/product-list",
];

const supervisorMerchantRoute = [
  // vendor
  "/vendor/market-list",
  "/vendor/edit-market",
  "/vendor/created-market",
  // order
  "/order/order-list",
  "/order/create-order",
  "/order/edit-order",
  "/order/edit-customer-address",
  // export order
  "/order/export-order",
  // product
  "/e-commerce/products",
  "/e-commerce/create-product",
  "/e-commerce/edit-product",
  // export product
  "/e-commerce/export-product",
  // order shopper
  "/shopper/order-list",
  // product order
  "/product-order-management/product-list",
  "/product-order-management/shopper-attendance",
  "/shopper/product-list",
];

const shopperRoute = [
  // order
  "/shopper/order-list",
  // product order
  "/shopper/product-list",
];

export const checkRoutePermission = (roles, route) => {
  // if access public route
  if (publicRoute.includes(route)) {
    return true;
  }

  if (roles.includes(SUPERADMIN)) {
    return true;
  }

  if (roles.includes(SUPERVISOR)) {
    return supervisorRoute.includes(route);
  }

  if (roles.includes(SUPERVISOR_MERCHANT)) {
    return supervisorMerchantRoute.includes(route);
  }

  if (roles.includes(SHOPPER)) {
    return shopperRoute.includes(route);
  }
};
