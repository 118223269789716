import { collection, query, where, getDocs, setDoc, doc } from "firebase/firestore";
import { db } from "../utilities/firebase";

export const getUserRole = async (email) => {
  try {
    const userRef = collection(db, "staff");
    const q = query(userRef, where("email", "==", email), where("status", "==", "active"));
    const usersCollection = await getDocs(q);
    if (usersCollection.docs.length) {
      const userData = usersCollection.docs[0].data();
      return Promise.resolve(userData);
    } else {
      return Promise.reject({ message: "can't find email" });
    }
  } catch (err) {
    console.log(err);
  }
};

export const getSupervisorCity = async (email) => {
  try {
    const userRef = collection(db, "staff");
    const q = query(userRef, where("email", "==", email));
    const usersCollection = await getDocs(q);
    const userData = usersCollection.docs[0].data();
    return userData.city;
  } catch (err) {
    console.log(err);
  }
};
