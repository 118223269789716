import { db } from "../utilities/firebase";
import {
  collection,
  getDocs,
  getDoc,
  doc,
  setDoc,
  query,
  orderBy,
  limit,
  startAfter,
  where,
  deleteDoc,
  writeBatch,
} from "firebase/firestore";
import { MAX_ITEM_PERPAGE } from "../variables/global";
import { mapSnapshootToDocs } from "../utilities/formatter";

export const getAllBranch = async ({ status, PAGE }) => {
  const branchRef = collection(db, "branches");
  let START_FROM = (PAGE - 1) * MAX_ITEM_PERPAGE;
  if (PAGE === 1) START_FROM = 1;

  const queryArr = [];
  queryArr.push(orderBy("id"));
  status && queryArr.push(where("status", "==", status));

  const first = query(branchRef, ...queryArr, limit(START_FROM));
  const firstSnapshot = await getDocs(first);
  let lastVisible = firstSnapshot.docs[firstSnapshot.docs.length - 1];

  if (PAGE === 1) lastVisible = null;
  const next = query(branchRef, ...queryArr, startAfter(lastVisible), limit(MAX_ITEM_PERPAGE));
  const nextSnapshoot = await getDocs(next);
  return mapSnapshootToDocs(nextSnapshoot);
};

export const postNewBranch = async (branchData) => {
  const id = branchData.name.toLowerCase().replace(" ", "-");
  branchData.id = id;
  branchData.search = [...branchData.name.toLowerCase().split(" "), branchData.name.toLowerCase()];
  // add data to cities collection
  const cityRef = doc(db, "cities", "cities-vendors");
  const snapshot = await getDoc(cityRef);
  const { cities } = snapshot.data();
  cities.push(branchData);
  await setDoc(cityRef, { cities }, { merge: true });

  // add data to branch collection
  const branchRef = doc(db, "branches", id);
  return setDoc(branchRef, branchData);
};

export const setStatusBranch = async (branch, status) => {
  try {
    const id = branch.id;
    const cityRef = doc(db, "cities", "cities-vendors");
    const snapshot = await getDoc(cityRef);
    const { cities } = snapshot.data();

    let newCityList = [...cities];
    branch.status = status;

    // if status branch inactive should update all related market status to inactive
    if (status === "inactive") {
      // remove all item in list_vendors cityList
      newCityList = newCityList.filter((b) => b.id !== id);
      branch.list_vendors = [];

      const vendorRef = collection(db, "vendors");
      const q = query(vendorRef, where("branch_id", "==", id));
      const vendorSnapshoot = await getDocs(q);
      const vendorList = mapSnapshootToDocs(vendorSnapshoot);

      // Get a new write batch
      const batch = writeBatch(db);
      vendorList.forEach((v) => {
        // Update the status of vendor
        const vendorRef = doc(db, "vendors", v.vendor_id);
        batch.update(vendorRef, { status: "inactive" });
      });
      // Commit the batch
      await batch.commit();
    } else {
      newCityList.push(branch);
    }

    await setDoc(doc(db, "branches", id), branch);
    return setDoc(cityRef, { cities: newCityList }, { merge: true });
  } catch (err) {
    console.log(err);
  }
};

export const deleteBranch = async (branch) => {
  // delete from collection city
  const cityRef = doc(db, "cities", "cities-vendors");
  const snapshot = await getDoc(cityRef);
  const { cities } = snapshot.data();
  const filteredBranch = cities.filter((b) => b.id !== branch.id);

  // delete from branches collection
  await deleteDoc(doc(db, "branches", branch.id));
  return setDoc(cityRef, { cities: filteredBranch }, { merge: true });
};

export const updateBranch = async (branchData) => {
  const id = branchData.id;
  branchData.search = [...branchData.name.toLowerCase().split(" "), branchData.name.toLowerCase()];
  const cityRef = doc(db, "cities", "cities-vendors");
  const snapshot = await getDoc(cityRef);
  const { cities } = snapshot.data();
  const branchIndex = cities.findIndex((item) => item.id === id);
  const newCityList = [...cities];
  newCityList[branchIndex] = branchData;

  // update branches collection
  await setDoc(doc(db, "branches", id), branchData);
  // update cities collection
  await setDoc(cityRef, { cities: newCityList }, { merge: true });
};

export const getBranchByKeyword = async (keyword, status) => {
  const voucherRef = collection(db, "branches");
  let q = query(
    voucherRef,
    where("search", "array-contains", keyword.trim().toLowerCase()),
    where("status", "==", status)
  );

  try {
    const snapshot = await getDocs(q);
    const branchList = mapSnapshootToDocs(snapshot);
    return Promise.resolve(branchList);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getBranchList = async () => {
  try {
    const branchRef = collection(db, "branches");
    const q = query(branchRef);
    const branchSnapshot = await getDocs(q);
    const branchList = mapSnapshootToDocs(branchSnapshot);
    return Promise.resolve(branchList);
  } catch (err) {
    return Promise.reject(err);
  }
};
