import { ref, getDownloadURL, uploadBytes, deleteObject } from "firebase/storage";
import { storage } from "../utilities/firebase";

export const uploadFile = async (file, filePath) => {
  const storageRef = ref(storage, filePath);
  return uploadBytes(storageRef, file)
    .then((snapshot) => {
      return getDownloadURL(snapshot.ref);
    })
    .catch((err) => console.log(err));
};

export const deleteFile = async (fileRef) => {
  try {
    const deleteRef = ref(storage, fileRef);
    return deleteObject(deleteRef);
  } catch (err) {
    console.log(err);
  }
};

export const updateFile = async (file, newFileRef, oldFileRef) => {
  if (oldFileRef) {
    await deleteFile(oldFileRef);
  }
  return uploadFile(file, newFileRef);
};
