/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { SUPERADMIN, SUPERVISOR, SUPERVISOR_MERCHANT } from "../../../../../variables/global";
import { useSelector } from "react-redux";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const { roles } = useSelector(({ auth }) => ({
    roles: auth.roles,
  }));
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          hidden={
            !roles.includes(SUPERADMIN) && !roles.includes(SUPERVISOR)
            // && !roles.includes(SUPERVISOR_MERCHANT)
          }
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/* Components */}

        {/* App */}
        {/*begin::1 Level*/}
        <li
          hidden={!roles.includes(SUPERADMIN)}
          className={`menu-item menu-item-submenu ${getMenuItemActive("/app", true)}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/app">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} />
            </span>
            <span className="menu-text">App</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <ul className="menu-subnav">
                {/*begin::2 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive("/app/slider")}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/app/slider">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Slider</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}

                {/*begin::2 Level*/}
                <li className={`menu-item ${getMenuItemActive("/app/faq")}`} aria-haspopup="true">
                  <NavLink className="menu-link" to="/app/faq">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Faq</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}
                {/*begin::2 Level*/}
                <li
                  hidden={true}
                  className={`menu-item ${getMenuItemActive("/app/terms-of-use")}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/app/terms-of-use">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Syarat Penggunaan</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}
                {/*begin::2 Level*/}
                <li
                  hidden={true}
                  className={`menu-item ${getMenuItemActive("/app/privacy-policy")}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/app/privacy-policy">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Kebijakan Privasi</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}
                {/*begin::2 Level*/}
                <li
                  hidden={true}
                  className={`menu-item ${getMenuItemActive("/app/about-us")}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/app/about-us">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Tentang Kami</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}
              </ul>
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}

        {/* Recipe */}
        {/*begin::1 Level*/}
        <li
          hidden={!roles.includes(SUPERADMIN)}
          className={`menu-item menu-item-submenu ${getMenuItemActive("/recipe", true)}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/recipe">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Cooking/Dinner.svg")} />
            </span>
            <span className="menu-text">Recipe</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <ul className="menu-subnav">
                {/*begin::2 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive("/recipe/recipe-category")}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/recipe/recipe-category">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Recipe Category</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}
                {/*begin::2 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive("/recipe/recipe-list")}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/recipe/recipe-list">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Recipe List</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}
              </ul>
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}

        {/* Vendor */}
        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive("/vendor", true)}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
          hidden={
            !roles.includes(SUPERADMIN) &&
            !roles.includes(SUPERVISOR) &&
            !roles.includes(SUPERVISOR_MERCHANT)
          }
        >
          <NavLink className="menu-link menu-toggle" to="/vendor">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Home.svg")} />
            </span>
            <span className="menu-text">Vendor</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Vendor</span>
                </span>
              </li>

              {/* Inputs */}
              {/*begin::2 Level*/}
              <li
                hidden={!roles.includes(SUPERADMIN)}
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/vendor/branch-list"
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/vendor/branch-list">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Daftar Cabang</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/* Navigation */}
              {/*begin::2 Level*/}
              <li
                hidden={
                  !roles.includes(SUPERADMIN) &&
                  !roles.includes(SUPERVISOR) &&
                  !roles.includes(SUPERVISOR_MERCHANT)
                }
                className={`menu-item menu-item-submenu  ${getMenuItemActive(
                  "/vendor/market-list",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/vendor/market-list">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Daftar Pasar</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
              {/*begin::2 Level*/}
              <li
                hidden={!roles.includes(SUPERADMIN) && !roles.includes(SUPERVISOR)}
                className={`menu-item ${getMenuItemActive("/vendor/staff")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/vendor/staff">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Staff</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}

        {/* Order */}
        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive("/order", true)}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
          hidden={
            !roles.includes(SUPERADMIN) &&
            !roles.includes(SUPERVISOR) &&
            !roles.includes(SUPERVISOR_MERCHANT)
          }
        >
          <NavLink className="menu-link menu-toggle" to="/order">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Cart1.svg")} />
            </span>
            <span className="menu-text">Order</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <ul className="menu-subnav">
                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                  <span className="menu-link">
                    <span className="menu-text">Order</span>
                  </span>
                </li>

                {/*begin::2 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive("/order/order-list")}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/order/order-list">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Daftar Order</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}

                {/*begin::2 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive("/order/export-order")}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/order/export-order">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Export Order</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}
              </ul>
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}

        {/* Centre Fulfillment */}
        {/*begin::1 Level*/}
        <li
          hidden={
            !roles.includes(SUPERADMIN) &&
            !roles.includes(SUPERVISOR) &&
            !roles.includes(SUPERVISOR_MERCHANT)
          }
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/product-order-management",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/product-order-management">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Settings.svg")} />
            </span>
            <span className="menu-text">Pengelolaan Produk Order</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <ul className="menu-subnav">
                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                  <span className="menu-link">
                    <span className="menu-text">Pengelolaan Produk Order</span>
                  </span>
                </li>

                {/*begin::2 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/product-order-management/product-list"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/product-order-management/product-list">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Daftar Produk Order</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}

                {/*begin::2 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/product-order-management/shopper-attendance"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/product-order-management/shopper-attendance">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Kehadiran Shopper</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}
              </ul>
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}

        {/* Shopper */}
        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive("/shopper", true)}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/shopper">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Cart2.svg")} />
            </span>
            <span className="menu-text">Shopper</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <ul className="menu-subnav">
                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                  <span className="menu-link">
                    <span className="menu-text">Daftar Order Shopper</span>
                  </span>
                </li>
                {/*begin::2 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive("/shopper/order-list")}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/shopper/order-list">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Daftar Order Shopper</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}

                {/*begin::2 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive("/shopper/product-list")}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/shopper/product-list">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Daftar Produk Shopper</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}
              </ul>
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}

        {/* Product and Category */}
        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive("/e-commerce", true)}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
          hidden={
            !roles.includes(SUPERADMIN) &&
            !roles.includes(SUPERVISOR) &&
            !roles.includes(SUPERVISOR_MERCHANT)
          }
        >
          <NavLink className="menu-link menu-toggle" to="/e-commerce">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Food/Carrot.svg")} />
            </span>
            <span className="menu-text">Produk dan Kategori</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Produk dan Kategori</span>
                </span>
              </li>
              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/e-commerce/products")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/e-commerce/products">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Daftar Produk</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
              {/*begin::2 Level*/}
              <li
                hidden={!roles.includes(SUPERADMIN)}
                className={`menu-item ${getMenuItemActive("/e-commerce/categories")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/e-commerce/categories">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Daftar Kategori</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/e-commerce/export-product")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/e-commerce/export-product">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Export Produk</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}

        {/* LOYALTY */}
        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive("/loyalty", true)}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
          hidden={
            !roles.includes(SUPERADMIN) && !roles.includes(SUPERVISOR)
            // && !roles.includes(SUPERVISOR_MERCHANT)
          }
        >
          <NavLink className="menu-link menu-toggle" to="/loyalty">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Gift.svg")} />
            </span>
            <span className="menu-text">Loyalty</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <ul className="menu-subnav">
                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                  <span className="menu-link">
                    <span className="menu-text">Loyalty</span>
                  </span>
                </li>

                {/*begin::2 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive("/loyalty/customer-list")}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/loyalty/customer-list">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Daftar Customer</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}

                {/*begin::2 Level*/}
                <li
                  hidden={!roles.includes(SUPERADMIN)}
                  className={`menu-item ${getMenuItemActive("/loyalty/voucher-management")}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/loyalty/voucher-management">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Manajemen Voucher</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}

                {/*begin::2 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive("/loyalty/discount-management")}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/loyalty/discount-management">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Manajemen Diskon</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}

                {/*begin::2 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive("/loyalty/redeem-reward")}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/loyalty/redeem-reward">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Redeem Reward</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}

                {/*begin::2 Level*/}
                <li
                  hidden={!roles.includes(SUPERADMIN)}
                  className={`menu-item ${getMenuItemActive("/loyalty/reward-management")}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/loyalty/reward-management">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Manajemen Reward</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}
              </ul>
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}

        {/* WITHDRAWAL */}
        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive("/withdrawal", true)}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
          hidden={
            !roles.includes(SUPERADMIN) && !roles.includes(SUPERVISOR)
            // && !roles.includes(SUPERVISOR_MERCHANT)
          }
        >
          <NavLink className="menu-link menu-toggle" to="/withdrawal">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Money.svg")} />
            </span>
            <span className="menu-text">Withdrawal</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Withdrawal</span>
                </span>
              </li>

              <li
                hidden={roles.includes(SUPERADMIN)}
                className={`menu-item ${getMenuItemActive("/withdrawal/withdrawal-list")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/withdrawal/withdrawal-list">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Withdrawal</span>
                </NavLink>
              </li>

              <li
                hidden={!roles.includes(SUPERADMIN)}
                className={`menu-item ${getMenuItemActive("/withdrawal/request-list")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/withdrawal/request-list">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Request List</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}

        {/* Custom */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Custom</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        {/* Error Pages */}
        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive("/error", true)}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/error">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Error-circle.svg")} />
            </span>
            <span className="menu-text">Error Pages</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Error Pages</span>
                </span>
              </li>

              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/error/error-v1")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/error/error-v1">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Error Page - 1</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/error/error-v2")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/error/error-v2">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Error Page -2</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/error/error-v3")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/error/error-v3">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Error Page - 3</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/error/error-v4")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/error/error-v4">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Error Page - 4</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/error/error-v5")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/error/error-v5">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Error Page - 5</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/error/error-v6")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/error/error-v6">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Error Page - 6</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
