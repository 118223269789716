import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { takeEvery, select, put } from "redux-saga/effects";

const initialProductState = {
  newProduct: {
    product_detail: {
      product_name: "",
      hpp_price: 0,
      sell_price: 0,
      discount_price: 0,
      maximum_item: 0,
      discount_period_start: "",
      discount_period_end: "",
      short_description: "",
      long_description: "",
    },
    product_data: {
      sku: "",
      stock_status: "",
      product_stock: 0,
      unit_amount: 0,
      unit: "",
      selected_city: { list_vendors: [] },
      selected_market: {},
      product_length: 0,
      product_width: 0,
      product_height: 0,
    },
    product_gallery: {},
    product_category: {},
  },
  editProduct: {
    initial_product: {},
    product_detail: {
      product_name: "",
      hpp_price: 0,
      sell_price: 0,
      discount_price: 0,
      maximum_item: 0,
      discount_period_start: "",
      discount_period_end: "",
      short_description: "",
      long_description: "",
    },
    product_data: {
      sku: "",
      stock_status: "",
      product_stock: 0,
      unit_amount: 0,
      unit: "",
      selected_city: { list_vendors: [] },
      selected_market: {},
      product_length: 0,
      product_width: 0,
      product_height: 0,
    },
    product_gallery: {},
    product_category: {},
  },

  filter: {
    cityId: "",
    vendor_id: "",
    categoryId: "",
    stock_status: "",
    status: "",
    PAGE: "",
    keyword: "",
  },
};

export const productSlice = createSlice({
  name: "product",
  initialState: initialProductState,
  reducers: {
    setProductDetail: (state, action) => {
      state.newProduct.product_detail = action.payload;
    },
    setProductData: (state, action) => {
      state.newProduct.product_data = action.payload;
    },
    setProductGallery: (state, action) => {
      state.newProduct.product_gallery = action.payload;
    },
    setSelectedCategory: (state, action) => {
      state.newProduct.product_category = action.payload;
    },

    // edit product
    setInitialEditProduct: (state, action) => {
      state.editProduct.initial_product = action.payload;
    },
    setEditProductDetail: (state, action) => {
      state.editProduct.product_detail = action.payload;
    },
    setEditProductData: (state, action) => {
      state.editProduct.product_data = action.payload;
    },
    setEditProductGallery: (state, action) => {
      state.editProduct.product_gallery = action.payload;
    },
    setEditProductCategory: (state, action) => {
      state.editProduct.product_category = action.payload;
    },
    clearProductForm: (state) => {
      state.newProduct = initialProductState.newProduct;
      state.editProduct = initialProductState.editProduct;
    },

    // filter product page
    setProductFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
});

const { actions } = productSlice;

export const setProductDetail = (productDetail) => (dispatch) => {
  return dispatch(actions.setProductDetail(productDetail));
};

export const setProductData = (productData) => (dispatch) => {
  return dispatch(actions.setProductData(productData));
};

export const setProductGallery = (productGallery) => (dispatch) => {
  return dispatch(actions.setProductGallery(productGallery));
};

export const setSelectedCategory = (productCategory) => (dispatch) => {
  return dispatch(actions.setSelectedCategory(productCategory));
};

// edit product
export const setInitialEditProduct = (initialPRoduct) => (dispatch) => {
  return dispatch(actions.setInitialEditProduct(initialPRoduct));
};
export const setEditProductDetail = (productDetail) => (dispatch) => {
  return dispatch(actions.setEditProductDetail(productDetail));
};

export const setEditProductData = (productData) => (dispatch) => {
  return dispatch(actions.setEditProductData(productData));
};

export const setEditProductGallery = (productGallery) => (dispatch) => {
  return dispatch(actions.setEditProductGallery(productGallery));
};

export const setEditProductCategory = (productCategory) => (dispatch) => {
  return dispatch(actions.setEditProductCategory(productCategory));
};

export const clearProductForm = () => (dispatch) => {
  return dispatch(actions.clearProductForm());
};

export const setProductFilter = (filter) => (dispatch) => {
  return dispatch(actions.setProductFilter(filter));
};

export function* saga() {
  yield takeEvery(actions.setProductDetail, function* editProductListener() {
    let state = yield select((state) => {
      return state;
    });

    // yield put(actions.setEditOrderPriceData(price));
    // // triggering changedlist price calculation
    // yield put(actions.setChangedItemList(changedItemList));
  });
}
