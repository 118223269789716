import { createSlice } from "@reduxjs/toolkit";

const initialFaqState = {
  sequence: null,
  title: "",
  content: [
    {
      question: "",
      answer: "",
      sequence: null,
    },
  ],
  selectedContent: {
    question: "",
    answer: "",
    sequence: null,
  },
};

export const faqSlice = createSlice({
  name: "faq",
  initialState: initialFaqState,
  reducers: {
    setSelectedFaq: (state, action) => (state = { ...action.payload }),
    setSelectedContent: (state, action) => {
      state.selectedContent = { ...action.payload };
    },
  },
});

const { actions } = faqSlice;

export const setSelectedFaq = (data) => (dispatch) => {
  return dispatch(actions.setSelectedFaq(data));
};

export const setSelectedContent = (data) => (dispatch) => {
  return dispatch(actions.setSelectedContent(data));
};
