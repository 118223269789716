import { createSlice } from "@reduxjs/toolkit";

const initialStaffState = {
  supervisor: {
    staff_detail: {},
    staff_role: {},
  },
  courier: {
    staff_detail: {},
    staff_role: {},
    area_coverage: [],
  },
  shopper: {
    staff_detail: {},
    staff_role: {},
    area_coverage: [],
  },
  initialStaffData: {},
};

export const staffSlice = createSlice({
  name: "staff",
  initialState: initialStaffState,
  reducers: {
    setSupervisorData: (state, action) => {
      const key = action.payload.key;
      state.supervisor[key] = action.payload;
    },
    setCourierData: (state, action) => {
      const key = action.payload.key;
      if (key === "area_coverage") {
        state.courier[key] = [...action.payload.value];
      } else {
        state.courier[key] = action.payload;
      }
    },
    setShopperData: (state, action) => {
      const key = action.payload.key;
      if (key === "area_coverage") {
        state.shopper[key] = [...action.payload.value];
      } else {
        state.shopper[key] = action.payload;
      }
    },
    resetForm: (state) => {
      state.supervisor = initialStaffState.supervisor;
      state.courier = initialStaffState.courier;
      state.shopper = initialStaffState.shopper;
    },
    setInitialStaffData: (state, action) => {
      state.initialStaffData = action.payload;
    },
  },
});

const { actions } = staffSlice;
// SUPERVISOR
export const setDetailSupervisor = (supervisorDetail) => (dispatch) => {
  return dispatch(actions.setSupervisorData({ ...supervisorDetail, key: "staff_detail" }));
};
export const setRoleSupervisor = (supervisorRole) => (dispatch) => {
  return dispatch(actions.setSupervisorData({ ...supervisorRole, key: "staff_role" }));
};

//COURIER
export const setCourierDetail = (courierDetail) => (dispatch) => {
  return dispatch(actions.setCourierData({ ...courierDetail, key: "staff_detail" }));
};
export const setCourierRole = (courierRole) => (dispatch) => {
  return dispatch(actions.setCourierData({ ...courierRole, key: "staff_role" }));
};
export const setCourierCoverage = (courierCoverage) => (dispatch) => {
  return dispatch(actions.setCourierData({ value: [...courierCoverage], key: "area_coverage" }));
};

//SHOPPER
export const setShopperDetail = (shopperDetail) => (dispatch) => {
  return dispatch(actions.setShopperData({ ...shopperDetail, key: "staff_detail" }));
};
export const setShopperRole = (shopperRole) => (dispatch) => {
  return dispatch(actions.setShopperData({ ...shopperRole, key: "staff_role" }));
};
export const setShopperCoverage = (shopperCoverage) => (dispatch) => {
  return dispatch(actions.setShopperData({ value: [...shopperCoverage], key: "area_coverage" }));
};

// RESET FORM
export const resetForm = () => (dispatch) => {
  return dispatch(actions.resetForm());
};

// SET INITIAL STAFF DATA
export const setInitialStaffData = (initialStaffData) => (dispatch) => {
  return dispatch(actions.setInitialStaffData(initialStaffData));
};
