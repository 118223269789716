import { createSlice } from "@reduxjs/toolkit";

const initialCategoryState = {
  new_category: {
    category_name: "",
    category_desc: "",
    category_thumbnail: {},
  },
  edit_category: {
    initial_category: {},
    category_name: "",
    category_desc: "",
    category_thumbnail: {},
  },
};

export const categorySlice = createSlice({
  name: "category",
  initialState: initialCategoryState,
  reducers: {
    setNewCategory: (state, action) => {
      state.new_category = { ...state.new_category, ...action.payload };
    },
    setInitialCategory: (state, action) => {
      state.edit_category.initial_category = action.payload;
    },
    setEditCategory: (state, action) => {
      state.edit_category = { ...state.edit_category, ...action.payload };
    },
  },
});

const { actions } = categorySlice;

export const setNewCategory = (categoryData) => (dispatch) => {
  return dispatch(actions.setNewCategory(categoryData));
};
export const setInitialCategory = (initialCategoryData) => (dispatch) => {
  return dispatch(actions.setInitialCategory(initialCategoryData));
};
export const setEditCategory = (categoryData) => (dispatch) => {
  return dispatch(actions.setEditCategory(categoryData));
};
