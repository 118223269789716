import React, { Suspense, lazy } from "react";
import { useSelector } from "react-redux";
import { Redirect, Switch, Route, useHistory } from "react-router-dom";
import { checkRoutePermission } from "../utilities/checkRoute";
import { SHOPPER, SUPERVISOR_MERCHANT } from "../variables/global";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";

const DashboardPage = lazy(() => import("./modules/Dashboard/DashboardPage"));
const AppManagement = lazy(() => import("./modules/App/AppManagement"));
const Shopper = lazy(() => import("./modules/Shopper/Shopper"));
const ProductOrderManagement = lazy(() =>
  import("./modules/ProductOrderManagement/ProductOrderManagement")
);
const Recipe = lazy(() => import("./modules/Recipe/Recipe"));
const WithdrawalPage = lazy(() => import("./modules/Withdrawal/WithdrawalPage"));
const Vendors = lazy(() => import("./modules/Vendors/Vendors"));
const Order = lazy(() => import("./modules/Order/Order"));
const ProductCategories = lazy(() => import("./modules/Product_Categories/ProductCategories"));
const Loyalty = lazy(() => import("./modules/Loyalty/Loyalty"));

export default function BasePage() {
  const { location } = useHistory();
  const { roles } = useSelector(({ auth }) => ({
    roles: auth.roles,
  }));

  const { pathname } = location;

  if (!checkRoutePermission(roles, pathname)) {
    return (
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          {roles.includes(SHOPPER) ? (
            <Redirect to="/shopper/product-list" />
          ) : roles.includes(SUPERVISOR_MERCHANT) ? (
            <Redirect to="/order/order-list" />
          ) : (
            <Redirect to="/dashboard" />
          )}
        </Switch>
      </Suspense>
    );
  } else {
    return (
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          {
            /* Redirect from root URL to /dashboard. */
            <Redirect exact from="/" to="/dashboard" />
          }
          <Route path="/app" component={AppManagement} />
          <ContentRoute path="/recipe" component={Recipe} />
          <Route path="/vendor" component={Vendors} />
          <Route path="/order" component={Order} />
          <Route path="/product-order-management" component={ProductOrderManagement} />
          <ContentRoute path="/shopper" component={Shopper} />
          <Route path="/e-commerce" component={ProductCategories} />
          <Route path="/loyalty" component={Loyalty} />
          <ContentRoute path="/withdrawal" component={WithdrawalPage} />
          <ContentRoute path="/dashboard" component={DashboardPage} />
          <Redirect to="error/error-v1" />
        </Switch>
      </Suspense>
    );
  }
}

// /e-commerce/products
