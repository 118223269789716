export const MAX_ITEM_PERPAGE = 25;
export const ALL_BRANCH = "ALL";

export const STATUS_COMPLETED = "wc-completed";
export const STATUS_PENDING = "wc-pending";
export const STATUS_PROCESSED = "wc-processing";
export const STATUS_ONHOLD = "wc-on-hold";
export const STATUS_CANCELLED = "wc-cancelled";
export const STATUS_TRASH = "wc-trash";
export const STATUS_REFUNDED = "wc-refunded";

export const STATUS_REWARD_PENDING = "pending";
export const STATUS_REWARD_PROCESSING = "processing";
export const STATUS_REWARD_SHIPPING = "shipping";
export const STATUS_REWARD_FINISH = "done";

export const STATUS_ORDER_SHOPPER_PENDING = "pending";
export const STATUS_ORDER_SHOPPER_SHOPPING = "shopping";
export const STATUS_ORDER_SHOPPER_SHIPPING = "shipping";

export const statusText = {
  [STATUS_COMPLETED]: "Selesai",
  [STATUS_PENDING]: "Pending",
  [STATUS_PROCESSED]: "Diproses",
  [STATUS_ONHOLD]: "On Hold",
  [STATUS_CANCELLED]: "Dibatalkan",
  [STATUS_TRASH]: "Dibuang",
  [STATUS_REFUNDED]: "Direfund",
};

const statusList = [];
for (const property in statusText) {
  statusList.push({
    display_name: statusText[property],
    key: property,
  });
}
export { statusList };

export const statusTextOrderShopper = {
  [STATUS_ORDER_SHOPPER_PENDING]: "Pending",
  [STATUS_ORDER_SHOPPER_SHOPPING]: "Dibelanjakan",
  [STATUS_ORDER_SHOPPER_SHIPPING]: "Diterima kurir",
};

export const CLOUD_FUNCTION_URL = process.env.REACT_APP_CLOUD_FUNCTION;

const statusListOrderShopper = [];
for (const property in statusTextOrderShopper) {
  statusListOrderShopper.push({
    display_name: statusTextOrderShopper[property],
    key: property,
  });
}
export { statusListOrderShopper };

export const SUPERADMIN = "superadmin";
export const SUPERVISOR = "supervisor";
export const SUPERVISOR_MERCHANT = "supervisor-merchant";
export const SHOPPER = "shopper";
export const COURIER = "courier";

export const FORMAT_IMAGES = ["image/png", "image/jpg", "image/jpeg"];
export const MAXIMAL_IMAGE_SIZE = 5242880;
