import { createSlice } from "@reduxjs/toolkit";
import { takeEvery, select, put } from "redux-saga/effects";
import { postNewBranch } from "../../../../services/branch";

const initialBranchState = {
  supervisor: { fname: "", lname: "", phone: "", email: "" },
  province: {
    city: "",
    country: "",
    postcode: "",
    province: "",
    timezone: "",
  },
  branch: "",
  newBranch: {},
  editBranch: {},
};

export const branchSlice = createSlice({
  name: "branch",
  initialState: initialBranchState,
  reducers: {
    setSupervisor: (state, action) => {
      state.supervisor = action.payload;
    },
    setProvince: (state, action) => {
      state.province = action.payload;
    },
    setBranchName: (state, action) => {
      state.branch = action.payload;
    },
    postBranch: (state, action) => {
      return state;
    },
    postUpdateBranch: (state, action) => {
      return state;
    },
    setCreatedBranch: (state, action) => {
      state.newBranch = action.payload;
    },
    setEditBranch: (state, action) => {
      state.editBranch = action.payload;
    },
    resetBranchForm: (state, action) => {
      state.supervisor = initialBranchState.supervisor;
      state.province = initialBranchState.province;
      state.branch = initialBranchState.branch;
    },
  },
});

const { actions } = branchSlice;

export const setSupervisor = (supervisorData) => (dispatch) => {
  return dispatch(actions.setSupervisor(supervisorData));
};

export const setProvince = (provinceData) => (dispatch) => {
  return dispatch(actions.setProvince(provinceData));
};

export const setBranchName = (branchName) => (dispatch) => {
  return dispatch(actions.setBranchName(branchName));
};

export const postBranch = () => (dispatch) => {
  return dispatch(actions.postBranch());
};

export const postUpdateBranch = () => (dispatch) => {
  return dispatch(actions.postUpdateBranch);
};

export const setCreatedBranch = (newBranch) => (dispatch) => {
  return dispatch(actions.setCreatedBranch(newBranch));
};

export const setEditBranch = (editBranch) => (dispatch) => {
  return dispatch(actions.setEditBranch(editBranch));
};

export const resetBranchForm = () => (dispatch) => {
  return dispatch(actions.resetBranchForm());
};

export function* saga() {
  yield takeEvery(actions.postBranch, function* postNewBranchSaga() {
    const { branch } = yield select();
    const newBranch = yield postNewBranch(branch);
    yield put(actions.setCreatedBranch(newBranch));
  });

  yield takeEvery(actions.postUpdateBranch, function* postUpdateBranchSaga() {
    // const { branch } = yield select();
    // const newBranch = yield postNewBranch(branch);
    // yield put(actions.setCreatedBranch(newBranch));
  });
}
