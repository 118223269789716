import { createSlice } from "@reduxjs/toolkit";
import { takeEvery, select, put, call } from "redux-saga/effects";
import { voucherValidationAdmin } from "../../../../services/voucher";

const initialOrderState = {
  orderEdited: {
    initialOrder: {},
    customerData: {
      order_date: new Date(),
      billing: {},
      shipping: {},
      payment: {
        payment_method: "",
        payment_method_title: "",
      },
      order_status: "",
      selected_city: {},
      selected_market: {},
    },
    courierData: {},
    itemList: [],
    changedItemList: [],
    priceData: {
      totalRetailPrice: 0,
      totalItemPrice: 0,
      discount: 0,
      // komponen biaya tambahan
      additional_shipping_cost: { total: 0, desc: "" },
      additional_item_cost: { total: 0, desc: "" },
      substract_item_cost: { total: 0, desc: "" },
      shippingCost: 0,
      additionalPrice: 0, // what is this ?
      totalOrderPrice: 0,
    },
    transactionTimeData: {
      date_created: new Date(),
      date_created_gmt: new Date(),
      date_paid: new Date(),
      date_paid_gmt: new Date(),
      date_modified: new Date(),
      date_modified_gmt: new Date(),
      date_completed: new Date(),
      date_completed_gmt: new Date(),
    },
    voucher: {
      initial_voucher: "",
      added_voucher: "",
      voucher_status: "initial",
      message: "",
    },
    swapItem: {
      product: {},
      index: null,
      target: "",
    },
    logs: [],
    loading: {
      voucher_validation: false,
    },
  },
  newOrder: {
    date: "",
    name: "",
    phone: "",
    email: "",
    payment_method: {},
    order_status: "",
    first_name: "",
    last_name: "",
    order_purpose: "",
    restaurant_name: "",
    address: "",
    selected_city: {},
    selected_market: {},
    courier: { name: "", price: 0 },
    listItem: [],
    priceData: {
      totalRetailPrice: 0,
      totalItemPrice: 0,
      discount: 0,
      // komponen biaya tambahan
      additional_shipping_cost: { total: 0, desc: "" },
      additional_item_cost: { total: 0, desc: "" },
      substract_item_cost: { total: 0, desc: "" },
      shippingCost: 0,
      additionalPrice: 0, // what is this ?
      totalOrderPrice: 0,
    },
    voucher: {
      voucher_code: "",
      voucher_status: "initial",
      message: "",
    },
    swapItem: {
      product: {},
      index: null,
    },
  },
  filter: {
    sort_filter: "default",
    status: "all",
    page: 1,
    keyword: "",
    value: 0,
  },
};

export const orderSlice = createSlice({
  name: "order",
  initialState: initialOrderState,
  reducers: {
    // EDIT ORDER
    setEditOrder: (state, action) => {
      state.orderEdited.initialOrder = action.payload;
    },
    setEditOrderCustomerData: (state, action) => {
      state.orderEdited.customerData = { ...state.orderEdited.customerData, ...action.payload };
    },
    setEditOrderCourierData: (state, action) => {
      state.orderEdited.courierData = { ...state.orderEdited.courierData, ...action.payload };
    },
    setEditOrderItemList: (state, action) => {
      state.orderEdited.itemList = [...action.payload];
    },
    setChangedItemList: (state, action) => {
      state.orderEdited.changedItemList = [...action.payload];
    },
    storeSelectedSwapItem: (state, action) => {
      state.orderEdited.swapItem = { ...action.payload };
    },
    setEditOrderPriceData: (state, action) => {
      state.orderEdited.priceData = { ...state.orderEdited.priceData, ...action.payload };
    },
    setEditOrderAdditionalPrice: (state, action) => {
      state.orderEdited.priceData = { ...state.orderEdited.priceData, ...action.payload };
    },
    setEditOrderVoucherData: (state, action) => {
      state.orderEdited.voucher = { ...state.orderEdited.voucher, ...action.payload };
    },
    setEditOrderAddLog: (state, action) => {
      state.orderEdited.logs.push(action.payload);
    },
    setTransactionTimeData: (state, action) => {
      state.orderEdited.transactionTimeData = {
        ...state.orderEdited.transactionTimeData,
        ...action.payload,
      };
    },
    setEditOrderLoading: (state, action) => {
      state.orderEdited.loading = {
        ...state.orderEdited.loading,
        ...action.payload,
      };
    },

    // NEW ORDER
    fillNewOrderForm: (state, action) => {
      state.newOrder = { ...state.newOrder, [action.payload.field]: action.payload.value };
    },
    setNewOrderCourier: (state, action) => {
      state.newOrder.courier = { ...action.payload };
    },
    setNewOrderListItem: (state, action) => {
      state.newOrder.listItem = [...action.payload];
    },
    storeNewOrderSwapItem: (state, action) => {
      state.newOrder.swapItem = { ...action.payload };
    },
    setNewOrderPriceData: (state, action) => {
      state.newOrder.priceData = { ...state.newOrder.priceData, ...action.payload };
    },
    setNewOrderAdditionalPrice: (state, action) => {
      state.newOrder.priceData = { ...state.newOrder.priceData, ...action.payload };
    },
    setNewOrderVoucherData: (state, action) => {
      state.newOrder.voucher = { ...state.newOrder.voucher, ...action.payload };
    },

    // clear order form
    clearOrderForm: (state, action) => {
      state.orderEdited = { ...initialOrderState.orderEdited };
      state.newOrder = { ...initialOrderState.newOrder };
    },

    // filter order page
    setOrderFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
});

const { actions } = orderSlice;

// edit order
// initial order
export const setEditOrder = (orderData) => (dispatch) => {
  return dispatch(actions.setEditOrder(orderData));
};
// customer data
export const setEditOrderCustomerData = (customerData) => (dispatch) => {
  return dispatch(actions.setEditOrderCustomerData(customerData));
};
// courier data
export const setEditOrderCourierData = (courierData) => (dispatch) => {
  return dispatch(actions.setEditOrderCourierData(courierData));
};
// initial item list
export const setEditOrderItemList = (itemList) => (dispatch) => {
  return dispatch(actions.setEditOrderItemList(itemList));
};
// changed item list by add new item
export const setChangedItemList = (itemList) => (dispatch) => {
  return dispatch(actions.setChangedItemList(itemList));
};
// changed item list individually (SWAP ITEM)
export const storeSelectedSwapItem = (item) => (dispatch) => {
  return dispatch(actions.storeSelectedSwapItem(item));
};
// add list item log
export const setEditOrderAddLog = (log) => (dispatch) => {
  return dispatch(actions.setEditOrderAddLog(log));
};
// price data
export const setEditOrderPriceData = (priceData) => (dispatch) => {
  return dispatch(actions.setEditOrderPriceData(priceData));
};
//  additional price
export const setEditOrderAdditionalPrice = (additonalPrice) => (dispatch) => {
  return dispatch(actions.setEditOrderAdditionalPrice(additonalPrice));
};
// transaction time (updated at, created at, date payment, etc)
export const setTransactionTimeData = (transactionTimeData) => (dispatch) => {
  return dispatch(actions.setTransactionTimeData(transactionTimeData));
};
// related with voucher data
export const setEditOrderVoucherData = (voucherData) => (dispatch) => {
  return dispatch(actions.setEditOrderVoucherData(voucherData));
};
// clear data
export const clearOrderForm = () => (dispatch) => {
  return dispatch(actions.clearOrderForm());
};

// set loading
export const setEditOrderLoading = () => (dispatch) => {
  return dispatch(actions.setEditOrderLoading());
};

//
//
/// new order
export const fillNewOrderForm = (orderData) => (dispatch) => {
  return dispatch(actions.fillNewOrderForm(orderData));
};
// add item list
export const setNewOrderCourier = (courierData) => (dispatch) => {
  return dispatch(actions.setNewOrderCourier(courierData));
};
// add item list
export const setNewOrderListItem = (listItem) => (dispatch) => {
  return dispatch(actions.setNewOrderListItem(listItem));
};
// changed item list individually (SWAP ITEM)
export const storeNewOrderSwapItem = (item) => (dispatch) => {
  return dispatch(actions.storeNewOrderSwapItem(item));
};
// changed item list individually (SWAP ITEM)
export const setNewOrderVoucherData = (voucherData) => (dispatch) => {
  return dispatch(actions.setNewOrderVoucherData(voucherData));
};
// add additional cost
export const setNewOrderAdditionalPrice = (voucherData) => (dispatch) => {
  return dispatch(actions.setNewOrderAdditionalPrice(voucherData));
};

//

export const setOrderFilter = (filter) => (dispatch) => {
  return dispatch(actions.setOrderFilter(filter));
};
//
//
//
// saga edit order
export function* saga() {
  // when list item change
  yield takeEvery(actions.setEditOrderItemList, function* calculateTotalItemPrice() {
    let { itemList, changedItemList, initialShippingCost, priceData } = yield select((state) => {
      return {
        itemList: state.order.orderEdited.itemList,
        changedItemList: state.order.orderEdited.changedItemList,
        initialShippingCost: +state.order.orderEdited.initialOrder.order_detail.shipping_total,
        priceData: state.order.orderEdited.priceData,
        voucher: state.order.orderEdited.voucher,
        initialOrder: state.order.orderEdited.initialOrder,
        customerData: state.order.orderEdited.customerData,
      };
    });

    let {
      discount,
      additional_shipping_cost,
      additional_item_cost,
      substract_item_cost,
    } = priceData;

    // calculate price item
    let totalRetailPrice = 0;
    let totalItemPrice = 0;
    itemList.forEach((el) => {
      if (el.item_status === "instock") {
        totalRetailPrice += +el.cog_item_total_cost;
        totalItemPrice += +el.total;
      }
    });

    const price = {
      totalRetailPrice,
      totalItemPrice,
      shippingCost: initialShippingCost,
      totalOrderPrice:
        +totalItemPrice +
        +initialShippingCost +
        +additional_item_cost.total +
        +additional_shipping_cost.total -
        +substract_item_cost.total -
        +discount,
    };
    yield put(actions.setEditOrderPriceData({ discount, totalOrderPrice: price }));
    // triggering changedlist price calculation
    yield put(actions.setChangedItemList(changedItemList));
  });

  // when additional list item change
  yield takeEvery(actions.setChangedItemList, function* calculateTotalItemPrice() {
    let {
      changedItemList,
      itemList,
      voucher,
      initialShippingCost,
      priceData,
      initialOrder,
      customerData,
    } = yield select((state) => {
      return {
        changedItemList: state.order.orderEdited.changedItemList,
        itemList: state.order.orderEdited.itemList,
        voucher: state.order.orderEdited.voucher,
        initialShippingCost: +state.order.orderEdited.initialOrder.order_detail.shipping_total,
        priceData: state.order.orderEdited.priceData,
        initialOrder: state.order.orderEdited.initialOrder,
        customerData: state.order.orderEdited.customerData,
      };
    });

    let {
      discount,
      additional_shipping_cost,
      additional_item_cost,
      substract_item_cost,
    } = priceData;

    // calculate price
    let totalRetailPriceMainList = 0;
    let totalItemPriceMainList = 0;
    let totalRetailPriceAdditionalList = 0;
    let totalItemPriceAdditionalList = 0;
    //main list
    itemList.forEach((el) => {
      if (el.item_status === "instock") {
        totalRetailPriceMainList += +el.cog_item_total_cost;
        totalItemPriceMainList += +el.total;
      }
    });
    //additional list
    changedItemList.forEach((el) => {
      totalRetailPriceAdditionalList += +el.cog_item_total_cost;
      totalItemPriceAdditionalList += +el.total;
    });
    let totalRetailPrice = totalRetailPriceMainList + totalRetailPriceAdditionalList;
    let totalItemPrice = totalItemPriceMainList + totalItemPriceAdditionalList;

    const price = {
      totalRetailPrice,
      totalItemPrice,
      shippingCost: initialShippingCost,
      totalOrderPrice:
        +totalItemPrice +
        +initialShippingCost +
        +additional_item_cost.total +
        +additional_shipping_cost.total -
        +substract_item_cost.total -
        +discount,
    };
    yield put(actions.setEditOrderPriceData(price));

    const voucherCode = voucher.added_voucher || voucher.initial_voucher;

    if (voucherCode) {
      const subtotal = +totalItemPrice + +additional_item_cost.total - +substract_item_cost.total;
      const data = {
        voucher_code: voucherCode,
        customer_login: initialOrder.customer_login,
        email: customerData.billing.email,
        total: subtotal,
        payment_method: customerData.payment.payment_method,
        vendor_id: customerData.selected_market.vendor_id,
        order_id: initialOrder.ID,
      };

      //set loading voucher true
      yield put(
        actions.setEditOrderLoading({
          voucher_validation: true,
        })
      );

      try {
        const response = yield call(voucherValidationAdmin, data);
        yield put(
          actions.setEditOrderVoucherData({
            ...voucher,
            voucher_status: "valid",
            validation_response: response.data,
            message: "berhasil diterapkan",
          })
        );
        // set loading voucher false
        yield put(
          actions.setEditOrderLoading({
            voucher_validation: false,
          })
        );
      } catch (err) {
        const { status, message } = err.response.data;
        yield put(
          actions.setEditOrderVoucherData({
            voucher_code: voucherCode,
            voucher_status: "invalid",
            message,
          })
        );
        // set loading voucher false
        yield put(
          actions.setEditOrderLoading({
            voucher_validation: false,
          })
        );
      }
    }
  });

  // when voucher change
  yield takeEvery(actions.setEditOrderVoucherData, function* calculateDiscount() {
    const {
      voucher_status,
      priceData,
      voucher_validation_response,
      initial_discount,
    } = yield select((state) => {
      return {
        initial_voucher_code: state.order.orderEdited.voucher.initial_voucher,
        added_voucher_code: state.order.orderEdited.voucher.added_voucher,
        voucher_status: state.order.orderEdited.voucher.voucher_status,
        priceData: state.order.orderEdited.priceData,
        voucher_validation_response: state.order.orderEdited.voucher.validation_response,
        initial_discount: +state.order.orderEdited.initialOrder.order_detail.discount_total,
      };
    });

    const {
      totalItemPrice,
      shippingCost,
      additional_shipping_cost,
      additional_item_cost,
      substract_item_cost,
    } = priceData;
    let discount = 0;
    if (voucher_status === "initial") discount = initial_discount;
    if (voucher_status === "invalid") discount = 0;

    const subtotal = +totalItemPrice + +additional_item_cost.total - +substract_item_cost.total;

    // get voucher detail
    if (voucher_status === "valid" && voucher_validation_response) {
      const { discount_percentage, maximum_discount, minimum_spend } = voucher_validation_response;
      // calculate discount amount
      if (subtotal >= minimum_spend) {
        discount =
          +subtotal * (+discount_percentage / 100) > +maximum_discount
            ? +maximum_discount
            : +subtotal * (+discount_percentage / 100);
      }
    }

    let total =
      +totalItemPrice +
      +shippingCost +
      +additional_item_cost.total +
      +additional_shipping_cost.total -
      +substract_item_cost.total -
      +discount;

    yield put(actions.setEditOrderPriceData({ discount, totalOrderPrice: total }));
  });

  // when additional price changed
  yield takeEvery(actions.setEditOrderAdditionalPrice, function* calculateAdditionalPrice() {
    const { priceData, voucher, initialOrder, customerData } = yield select((state) => {
      return {
        priceData: state.order.orderEdited.priceData,
        voucher: state.order.orderEdited.voucher,
        initialOrder: state.order.orderEdited.initialOrder,
        customerData: state.order.orderEdited.customerData,
      };
    });

    let { totalItemPrice, additional_item_cost, substract_item_cost } = priceData;

    const voucherCode = voucher.added_voucher || voucher.initial_voucher;

    if (voucherCode) {
      const subtotal = +totalItemPrice + +additional_item_cost.total - +substract_item_cost.total;
      const data = {
        voucher_code: voucherCode,
        customer_login: initialOrder.customer_login,
        email: customerData.billing.email,
        total: subtotal,
        payment_method: customerData.payment.payment_method,
        vendor_id: customerData.selected_market.vendor_id,
        order_id: initialOrder.ID,
      };

      //set loading voucher true
      yield put(
        actions.setEditOrderLoading({
          voucher_validation: true,
        })
      );

      try {
        const response = yield call(voucherValidationAdmin, data);
        yield put(
          actions.setEditOrderVoucherData({
            ...voucher,
            voucher_status: "valid",
            validation_response: response.data,
            message: "berhasil diterapkan",
          })
        );
        // set loading voucher false
        yield put(
          actions.setEditOrderLoading({
            voucher_validation: false,
          })
        );
      } catch (err) {
        const { status, message } = err.response.data;
        yield put(
          actions.setEditOrderVoucherData({
            voucher_code: voucherCode,
            voucher_status: "invalid",
            message,
          })
        );
        // set loading voucher false
        yield put(
          actions.setEditOrderLoading({
            voucher_validation: false,
          })
        );
      }
    }
  });

  //
  //
  // saga NEW ORDER
  // when list item change
  yield takeEvery(actions.setNewOrderListItem, function* calculateTotalItemPrice() {
    let { listItem, priceData, shippingCost } = yield select(({ order }) => {
      return {
        listItem: order.newOrder.listItem,
        priceData: order.newOrder.priceData,
        shippingCost: order.newOrder.courier.price,
      };
    });
    let {
      discount,
      additional_shipping_cost,
      additional_item_cost,
      substract_item_cost,
    } = priceData;
    // calculate price
    let totalRetailPrice = 0;
    let totalItemPrice = 0;
    listItem.forEach((el) => {
      if (el.item_status === "instock") {
        totalRetailPrice += +el.cog_item_total_cost;
        totalItemPrice += +el.total;
      }
    });
    const price = {
      totalRetailPrice,
      totalItemPrice,
      shippingCost,
      totalOrderPrice:
        +totalItemPrice +
        +shippingCost +
        +additional_item_cost.total +
        +additional_shipping_cost.total -
        +substract_item_cost.total -
        +discount,
    };
    yield put(actions.setNewOrderPriceData(price));
  });

  // when voucher change
  yield takeEvery(actions.setNewOrderVoucherData, function* calculateDiscount() {
    const { voucher_status, priceData, voucher_validation_response } = yield select((state) => {
      return {
        voucher_code: state.order.newOrder.voucher.voucher_code,
        voucher_status: state.order.newOrder.voucher.voucher_status,
        priceData: state.order.newOrder.priceData,
        voucher_validation_response: state.order.newOrder.voucher.validation_response,
      };
    });

    const {
      totalItemPrice,
      shippingCost,
      additional_shipping_cost,
      additional_item_cost,
      substract_item_cost,
    } = priceData;
    let discount = 0;
    if (voucher_status === "ïnvalid") return yield put(actions.setNewOrderPriceData({ discount }));

    // get voucher detail
    if (voucher_validation_response) {
      const { discount_percentage, maximum_discount, minimum_spend } = voucher_validation_response;
      // calculate discount amount
      if (+totalItemPrice >= +minimum_spend) {
        discount =
          +totalItemPrice * +discount_percentage > +maximum_discount
            ? +maximum_discount
            : +totalItemPrice * +discount_percentage;
      }
    }
    let total =
      +totalItemPrice +
      +shippingCost +
      +additional_item_cost.total +
      +additional_shipping_cost.total -
      +substract_item_cost.total -
      +discount;
    yield put(actions.setNewOrderPriceData({ discount, totalOrderPrice: total }));
  });

  // when courier data change
  yield takeEvery(actions.setNewOrderCourier, function* recalculationPriceOnCourierChange() {
    const { priceData, shippingCost } = yield select(({ order }) => {
      return {
        priceData: order.newOrder.priceData,
        shippingCost: order.newOrder.courier.price,
      };
    });

    const {
      totalItemPrice,
      additional_shipping_cost,
      additional_item_cost,
      substract_item_cost,
      discount,
    } = priceData;

    let total =
      +totalItemPrice +
      +shippingCost +
      +additional_item_cost.total +
      +additional_shipping_cost.total -
      +substract_item_cost.total -
      +discount;
    yield put(actions.setNewOrderPriceData({ discount, totalOrderPrice: total }));
  });

  // when additional price changed
  yield takeEvery(actions.setNewOrderAdditionalPrice, function* calculateAdditionalPrice() {
    const { priceData } = yield select((state) => {
      return {
        priceData: state.order.newOrder.priceData,
      };
    });

    const {
      totalItemPrice,
      shippingCost,
      additional_shipping_cost,
      additional_item_cost,
      substract_item_cost,
      discount,
    } = priceData;

    let total =
      +totalItemPrice +
      +shippingCost +
      +additional_item_cost.total +
      +additional_shipping_cost.total -
      +substract_item_cost.total -
      +discount;
    yield put(actions.setNewOrderPriceData({ discount, totalOrderPrice: total }));
  });
}
